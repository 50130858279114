import { render, staticRenderFns } from "./StepThreeUpload.vue?vue&type=template&id=29f6c7d1&scoped=true&"
import script from "./StepThreeUpload.vue?vue&type=script&lang=js&"
export * from "./StepThreeUpload.vue?vue&type=script&lang=js&"
import style0 from "./StepThreeUpload.vue?vue&type=style&index=0&id=29f6c7d1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29f6c7d1",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/XDCWallet/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('29f6c7d1')) {
      api.createRecord('29f6c7d1', component.options)
    } else {
      api.reload('29f6c7d1', component.options)
    }
    module.hot.accept("./StepThreeUpload.vue?vue&type=template&id=29f6c7d1&scoped=true&", function () {
      api.rerender('29f6c7d1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/dapps/staked-dapp/components/staked-stepper/staked-steps/StepThreeUpload.vue"
export default component.exports